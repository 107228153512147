export 
const strIsEmpty = (str)=>{
    if (str===undefined){
        return true;
    }
    const validStr = str.trim();
    if (validStr===''){
        return true;
    }
    return false;
}