import { NavBar } from "../../component/NavBar"
import { SideBar } from "../../component/SideBar"
import { ImgButton } from "../../component/ImgButton"
import { useCallback, useEffect, useState } from "react"
import { traineAdd, traineList } from "../../service/TraineeService"
import { useAppContext } from "../../context/AppContext"
import { useNavigate } from "react-router"
import { Spinner } from "../../component/Spinner"
import { useCookies } from "react-cookie"
import { Config, ConfigCookies, ConfigTestCaseStatus } from "../../Config"

const ADD_TRAINI_DLG = 'addTrainiDialogId'

const TraineeData = ({traines, handleAddTrainiModal})=>{
    const [data, setData] = useState(traines);
    const { isAuthenticated } = useAppContext();
    const Navigate = useNavigate();

    if (!isAuthenticated()){
        Navigate("../login")
    }

    useEffect(() => {
        setData(traines)
    }, [traines]);

    return (<table className="gridData">
    <tbody>
        <tr>
            <th rowSpan="2"><ImgButton handleClick={handleAddTrainiModal} alt="Add member" asset="plus.png"/> User</th>
            <th rowSpan="2">Email date</th>
            <th rowSpan="2">Progress %</th>
            <th colSpan={2}>Case 1</th>
            <th colSpan={2}>Case 2</th>
            <th colSpan={2}>Case 3</th>
            <th rowSpan="2">Testcase</th>
            <th rowSpan="2">Evaluation</th>
        </tr>
        <tr>
            <th>Start End</th><th>Status</th>
            <th>Start End</th><th>Status</th>
            <th>Start End</th><th>Status</th>
        </tr>
        
        {Object.keys(data).map((key) => (
           
            <tr key={data[key]._id}>
                <td>{data[key].name}</td>
                <td>{data[key].emailDate}</td>
                <td>{Number((data[key].progress*100)/3).toFixed(0)}</td>
                <td>{data[key].testcase['Practice Case 1'].startDate}<br/>{data[key].testcase['Practice Case 1'].endDate}</td>
                <td>{ConfigTestCaseStatus[data[key].testcase['Practice Case 1'].status].label}</td>
                <td>{data[key].testcase['Practice Case 2'].startDate}<br/>{data[key].testcase['Practice Case 2'].endDate}</td>
                <td>{ConfigTestCaseStatus[data[key].testcase['Practice Case 2'].status].label}</td>
                <td>{data[key].testcase['Practice Case 3'].startDate}<br/>{data[key].testcase['Practice Case 3'].endDate}</td>
                <td>{ConfigTestCaseStatus[data[key].testcase['Practice Case 3'].status].label}</td>
                <td><a href={'/testcase?trainee_id=' + data[key]._id} target="_new">testcase</a></td>
                {
                    ( data[key].testCase?<td><a href={'/patient-weeks-test?testcase_id=' + data[key].testCase} >Evaluation</a></td>:<td>0 cases completed</td>)                    
                }
                
            </tr>
            
        ))}

    </tbody>
</table>)
}

export const TrainiListPage = ()=>{
    const [cookies] = useCookies(ConfigCookies.USER_ID)
    const [loading, setLoading] = useState(false)
    const [name , setName] = useState('');
    const [email , setEmail] = useState('');
    const [county , setCounty] = useState('');
    const [practice , setPractice] = useState('');
    
    const [traines, setTraines] = useState({});
    const [errorMessage, setErrorMessage] = useState('')
    
    const fetchTraineData = useCallback(async ()=>{
        setLoading(true)
        setTraines(await traineList());
        setLoading(false)
    },[])

    useEffect(()=>{
        fetchTraineData();
    }, [fetchTraineData])
    

    const handleSubmitAddTrainiModal = async ()=>{
        setLoading(true)
        setErrorMessage('');
        const response = await traineAdd({name, email, practice:Config.TRAINEE_PRACTICE_ID, assigned:cookies.userId})
        setLoading(false)
        if (response.error){
            setErrorMessage(response.error);
        }else{
            const dialog = document.getElementById(ADD_TRAINI_DLG)
            dialog.close();
            fetchTraineData();
        }
    }
    const handleAddTrainiModal = async ()=>{
        const dialog = document.getElementById(ADD_TRAINI_DLG)
        dialog.showModal();
        setName('')
        setEmail('')
        setCounty('');
        setPractice('');
    }

    return(
        <div>
            <NavBar/>
            <SideBar selector={'dashboard'}/>
            <div className="container">
                <h1>Trainer</h1>

                <TraineeData traines={traines} handleAddTrainiModal={handleAddTrainiModal}/>

            
            <dialog open={false} id={ADD_TRAINI_DLG} style={{zIndex:"100"}}>
                <h1>Add Trainee</h1>
                <table className="formContainer">
                    <tbody>
                        <tr><td>Name :</td><td><input type="text" value={name} onChange={(e)=>setName(e.target.value)}/></td></tr>
                        <tr><td>Email :</td><td><input type="text" value={email} onChange={(e)=>setEmail(e.target.value)} /></td></tr>
                        <tr><td>County :</td><td><input type="text" value={county} onChange={(e)=>setCounty(e.target.value)}/></td></tr>
                        <tr><td>Practice :</td><td><input type="text" value={practice} onChange={(e)=>setPractice(e.target.value)}/></td></tr>
                        <tr><td colSpan={2} style={{color:"red",textAlign:"center"}}>{errorMessage}</td></tr>
                    </tbody>
                </table>
                <div style={{textAlign:'center'}}>
                <button type="button" disabled={county===''||practice===''||name===''||email===''} className='greenButton' onClick={handleSubmitAddTrainiModal}>Submit</button>
                </div>
                <Spinner loading={loading}></Spinner>
            </dialog>
            </div>
            
        </div>
    )
}